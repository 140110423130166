var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center align-center main-content",style:({ 'background-image': 'url(' + _vm.bgPath + ')' })},[_c('div',{staticClass:"call-to-action",style:({
      'background-color': ("rgba(" + (_vm.hexToRgba(
        _vm.lightTheme['primary-alt'],
        0.95
      )) + ")")
    })},[_c('a',{attrs:{"href":_vm.campaign.page_url,"target":"_blank"}},[_c('img',{staticClass:"pa-4 campaign-feature-image",attrs:{"src":_vm.assets.campaign_logo,"alt":"sogorea te land trust"}})]),_c('h2',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.campaignTagline)+" ")]),_c('h3',{staticClass:"white--text"},[_vm._v("Make Your Voice Count")]),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"mt-2 mb-4 mx-3 action-btn",attrs:{"elevation":"2","color":"secondary","to":{
          name: 'Campaign',
          params: { campaignId: _vm.campaign.id || 'undefined' }
        }}},[_vm._v(" Write Your Representatives ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }